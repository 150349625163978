import * as React from "react";
import { createRef } from "react";
import { hot } from "react-hot-loader";
import { withRouter } from "react-router-dom";
import "./MapView.scss";
import { mapbox } from "../../mapbox";
import "mapbox-gl/dist/mapbox-gl.css";
import { Typography, Row, Col } from "antd";
const { Title } = Typography;
import { Radio } from "antd";
import { UserContext } from "../../providers/UserProvider";

import { analytics } from "../../amplitude";

// https://github.com/mourner/suncalc
import * as SunCalc from "suncalc";
// https://momentjs.com/docs/
import * as moment from "moment-timezone";

// https://github.com/lhz516/react-h5-audio-player#readme
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/src/styles.scss";

// https://github.com/darkskyapp/tz-lookup-oss/
import * as tzlookup from "tz-lookup";

type GeoJsonProperties = {
  name: string;
  osm_id: string;
  osm_type: string;
  natural: string;
  type: string;
  full_id: string;
  wikidata: string;
  surface: string;
  access: string;
  wikipedia: string;
  attribution: string;
  boundary: string;
  leisure: string;
  park_type: string;
  csp_unitcode: string;
  center: string;
};

type MapViewStateForMeditation = {
  mapBox: any;
  mapContainerRef: HTMLDivElement;
  lng: number;
  lat: number;
  zoom: number;
  selectedArea: GeoJsonProperties;
  selectedDate: Date;
  clickedCoordinates: any;
};

//https://github.com/firebase/firebaseui-web-react
//https://github.com/alex3165/react-mapbox-gl
class MapView extends React.Component<
  Record<string, unknown>,
  MapViewStateForMeditation
> {
  private meditations = [
    {
      audioSrc: "01_Breathing_Meditation.mp3",
      description: "",
      title: "Breathing",
    },
    {
      audioSrc: "02_Breath_Sound_Body_Meditation.mp3",
      description: "",
      title: "Breath and Sound Body",
    },
    {
      audioSrc: "03_Complete_Meditation_Instructions.mp3",
      description: "",
      title: "Instructions",
    },
    {
      audioSrc: "04_Meditation_for_Working_with_Difficulties.mp3",
      description: "",
      title: "Working with difficulties",
    },
    {
      audioSrc: "05_Loving_Kindness_Meditation.mp3",
      description: "",
      title: "Loving Kindness",
    },
    {
      audioSrc: "Body-Scan-Meditation.mp3",
      description: "",
      title: "Body Scan",
    },
    {
      audioSrc: "Body-Scan-for-Sleep.mp3",
      description: "",
      title: "Body Scan for sleep",
    },
    {
      audioSrc: "Body-Sound-Meditation.mp3",
      description: "",
      title: "Body Sound",
    },
  ];

  public readonly state: MapViewStateForMeditation = {
    mapBox: null,
    mapContainerRef: null,
    lng: -122.44,
    lat: 37.7508,
    zoom: 11.26,
    selectedArea: null,
    selectedDate: new Date(),
    clickedCoordinates: null,
  };

  static contextType = UserContext;
  private mapInstanceRef = createRef<HTMLDivElement>();
  private sunriseLineRef = createRef<SVGLineElement>();
  private sunriseIconRef = createRef<SVGSVGElement>();
  private sunsetLineRef = createRef<SVGLineElement>();
  private sunsetIconRef = createRef<SVGSVGElement>();

  constructor(props) {
    super(props);

    this.onMapMove = this.onMapMove.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    this.onMapLoad = this.onMapLoad.bind(this);
    this.onMapRender = this.onMapRender.bind(this);
    this.onDateChanged = this.onDateChanged.bind(this);
    this.onPlayMeditation = this.onPlayMeditation.bind(this);

    analytics.getInstance().logEvent("MeditationMapView");
  }

  public componentDidMount() {
    const map = new mapbox.Map({
      container: this.mapInstanceRef.current,
      style: "mapbox://styles/mapbox/outdoors-v11",
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
    });

    map.on("move", this.onMapMove);
    map.on("click", this.onMapClick);
    map.on("load", this.onMapLoad);
    map.on("render", this.onMapRender);

    map.addControl(new mapbox.NavigationControl());
    map.addControl(new mapbox.ScaleControl({ position: "bottom-right" }));

    this.setState({ mapBox: map });
  }

  private onMapLoad() {
    this.state.mapBox.addSource("meditation-places", {
      type: "geojson",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data: window.appSettings.placesJsonUrl,
    });
  }

  private onMapRender() {
    if (this.state.mapBox.getSource("meditation-places")) {
      if (this.state.mapBox.isSourceLoaded("meditation-places")) {
        if (!this.state.mapBox.getLayer("maine")) {
          this.state.mapBox.addLayer({
            id: "maine",
            type: "fill",
            source: "meditation-places", // reference the data source
            layout: {},
            paint: {
              "fill-color": "#ffff00", // blue color fill
              "fill-opacity": 0.3,
            },
            filter: ["==", "$type", "Polygon"],
          });
          // Add a black outline around the polygon.
          this.state.mapBox.addLayer({
            id: "outline",
            type: "line",
            source: "meditation-places",
            layout: {},
            paint: {
              "line-color": "#f00",
              "line-width": 3,
            },
            filter: ["==", "$type", "Polygon"],
          });

          this.state.mapBox.addLayer({
            id: "circles",
            type: "circle",
            source: "meditation-places",
            paint: {
              "circle-radius": 6,
              "circle-color": "#B42222",
            },
            filter: ["==", "$type", "Point"],
            minzoom: 10,
          });

          this.state.mapBox.addLayer({
            id: "circles-hidden-for-better-touch",
            type: "circle",
            source: "meditation-places",
            paint: {
              "circle-radius": 12,
              "circle-color": "#B42222",
              "circle-opacity": 0,
            },
            filter: ["==", "$type", "Point"],
            minzoom: 10,
          });
        }
      }
    }

    if (this.state.selectedArea) {
      const center = JSON.parse(this.state.selectedArea.center);
      this.setState({
        clickedCoordinates: this.state.mapBox.project([center[0], center[1]]),
      });
    }
  }

  public componentWillUnmount() {
    this.state.mapBox.off("move", this.onMapMove);
    this.state.mapBox.off("click", this.onMapClick);
  }

  private onPlayMeditation(e) {
    analytics
      .getInstance()
      .logEvent("MeditationPlayClicked", { src: e.target.currentSrc });
  }

  private onMapMove(e) {
    this.setState({
      lat: this.state.mapBox.getCenter().lat.toFixed(4),
      lng: this.state.mapBox.getCenter().lng.toFixed(4),
      zoom: this.state.mapBox.getZoom().toFixed(2),
    });
  }

  private onMapClick(e) {
    const features = this.state.mapBox.queryRenderedFeatures(e.point, {
      layers: ["maine", "circles", "circles-hidden-for-better-touch"],
    });
    if (0 < features.length) {
      const now = new Date();
      const tomorrow = moment(now).add(1, "d");
      this.setState({
        selectedArea: features[0].properties,
        clickedCoordinates: e.point,
        selectedDate: tomorrow.toDate(),
      });

      analytics
        .getInstance()
        .logEvent("ViewMeditationPlace", features[0].properties);
    } else {
      this.sunriseLineRef.current.style.display = "none";
      this.sunriseIconRef.current.style.display = "none";
      this.sunsetIconRef.current.style.display = "none";
      this.sunsetLineRef.current.style.display = "none";

      const now = new Date();
      const tomorrow = moment(now).add(1, "d");

      this.setState({
        selectedArea: null,
        clickedCoordinates: null,
        selectedDate: tomorrow.toDate(),
      });
    }
  }

  private drawLine(line, icon, cx, cy, dx, dy) {
    line.style.display = "";

    this.val(line.x1, cx);
    this.val(line.y1, cy);
    this.val(line.x2, cx + dx);
    this.val(line.y2, cy + dy);

    if (icon) {
      icon.style.display = "block";
      this.val(
        icon.x,
        this.val(line.x2, null) - this.val(icon.width, null) / 2,
      );
      this.val(
        icon.y,
        this.val(line.y2, null) - this.val(icon.height, null) / 2,
      );
    }
  }

  private val(attr, value) {
    if (value !== null) {
      attr.baseVal.value = value;
    } else {
      return attr.baseVal.value;
    }
  }

  private drawGroundLine(line, icon, angle, pitchCos, cx, cy, pad) {
    const sunSin = Math.cos(angle);
    const sunCos = Math.sin(angle);
    const r =
      Math.min(Math.abs(cx / sunSin), Math.abs(cy / sunCos / pitchCos)) + pad;

    this.drawLine(line, icon, cx, cy, 40 * sunSin, 40 * sunCos * pitchCos);
  }

  private onDateChanged(e) {
    this.setState({
      selectedDate: moment(new Date()).add(e.target.value, "days").toDate(),
    });
  }

  public render() {
    let selected = (
      <Row>
        <Col offset={1} span={22}>
          <Typography.Title level={2}>
            Touch an area on the map above to see available meditations
          </Typography.Title>
        </Col>
        <Col offset={1} span={22}>
          This map contains meditation places such as beaches, peeks and etc.
          Email{" "}
          <a href={"mailto:mindfulhikingcoach@gmail.com"}>
            mindfulhikingcoach@gmail.com
          </a>
          .
        </Col>
        <Col offset={1} span={22}></Col>
      </Row>
    );

    if (this.state.selectedArea) {
      const center = JSON.parse(this.state.selectedArea.center);
      const time = SunCalc.getTimes(
        this.state.selectedDate,
        center[1],
        center[0],
      );
      const sunriseTime = time.sunrise;
      const sunsetTime = time.sunset;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const basePathForMeditations = window.appSettings.basePath;

      const tr = this.state.mapBox.transform;
      const cx = this.state.clickedCoordinates.x;
      const cy = this.state.clickedCoordinates.y;

      const loc = { lat: center[1], lng: center[0] };
      const sunriseAngle =
        SunCalc.getPosition(sunriseTime, loc.lat, loc.lng).azimuth +
        Math.PI / 2 +
        tr.angle;
      const sunsetAngle =
        SunCalc.getPosition(sunsetTime, loc.lat, loc.lng).azimuth +
        Math.PI / 2 +
        tr.angle;
      const pitchCos = Math.cos((tr.pitch * Math.PI) / 180);

      this.drawGroundLine(
        this.sunriseLineRef.current,
        this.sunriseIconRef.current,
        sunriseAngle,
        pitchCos,
        cx,
        cy,
        -30,
      );

      this.drawGroundLine(
        this.sunsetLineRef.current,
        this.sunsetIconRef.current,
        sunsetAngle,
        pitchCos,
        cx,
        cy,
        -30,
      );

      const timezone = tzlookup(center[1], center[0]);

      const now = new Date();
      const defaultValue = moment(this.state.selectedDate).diff(
        moment(now),
        "days",
      );
      const nowForDisplay = moment(now);

      selected = (
        <Row>
          <Col offset={1} span={22}>
            <Typography.Title level={2}>
              {this.state.selectedArea.name} (
              {this.state.selectedArea.natural.replace("_", " ")})
            </Typography.Title>
          </Col>
          <Col offset={1} span={22}>
            <Radio.Group
              defaultValue={defaultValue}
              buttonStyle="solid"
              onChange={this.onDateChanged}
            >
              {[1, 2, 3, 4, 5, 6, 7].map((m, i) => {
                return (
                  <Radio.Button value={i} key={"dates-" + i}>
                    {nowForDisplay.add(1, "days").format("Do of MMM")}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </Col>
          <Col offset={1} span={22}>
            &nbsp;
          </Col>
          <Col offset={1} span={22}>
            Sunrise: {moment(sunriseTime).tz(timezone).format("LT")}
          </Col>
          <Col offset={1} span={22}>
            Sunset: {moment(sunsetTime).tz(timezone).format("LT")}
          </Col>
          <Col offset={1} span={22}>
            &nbsp;
          </Col>
          <Col offset={1} span={22}>
            <Typography.Title level={3}>Meditations</Typography.Title>
          </Col>
          {this.meditations.map((m, i) => {
            return (
              <Col offset={1} span={22} key={`audio-key-${i}`}>
                <Typography.Title level={4} key={`audio-title-${i}`}>
                  {m.title}
                </Typography.Title>
                <AudioPlayer
                  preload={"none"}
                  key={`audio-player-${i}`}
                  src={basePathForMeditations + m.audioSrc}
                  showJumpControls={false}
                  autoPlayAfterSrcChange={false}
                  onPlay={this.onPlayMeditation}
                />
                <br />
              </Col>
            );
          })}
          <Col offset={1} span={22}>
            Meditation copyright -{" "}
            <a href={"https://www.uclahealth.org/marc/mindful-meditations"}>
              https://www.uclahealth.org/marc/mindful-meditations
            </a>
          </Col>
        </Row>
      );
    }

    return (
      <>
        <Row className={"meditation-map-view"}>
          <Col span={24}>
            <div
              className="meditation-map-container"
              ref={this.mapInstanceRef}
            />
            <svg id="viz" xmlns="http://www.w3.org/2000/svg" version="1.1">
              <line id="sun-dir" />
              <line id="sunrise" ref={this.sunriseLineRef} />
              <line id="sunset" ref={this.sunsetLineRef} />
              <line id="sun-pos" />
              <svg
                id="eye"
                display={"none"}
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M1,12S5,4,12,4s11,8,11,8-4,8-11,8S1,12,1,12Z" />
                <circle cx="12" cy="12" r="3" />
              </svg>
              <svg
                id="sun"
                display={"none"}
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <circle cx="12" cy="12" r="5" />
                <line x1="12" y1="1" x2="12" y2="3" />
                <line x1="12" y1="21" x2="12" y2="23" />
                <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
                <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
                <line x1="1" y1="12" x2="3" y2="12" />
                <line x1="21" y1="12" x2="23" y2="12" />
                <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
                <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
              </svg>
              <svg
                id="sunrise-icon"
                ref={this.sunriseIconRef}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                display={"none"}
              >
                <path d="M17,18A5,5,0,0,0,7,18" />
                <line x1="12" y1="2" x2="12" y2="9" />
                <line x1="4.22" y1="10.22" x2="5.64" y2="11.64" />
                <line x1="1" y1="18" x2="3" y2="18" />
                <line x1="21" y1="18" x2="23" y2="18" />
                <line x1="18.36" y1="11.64" x2="19.78" y2="10.22" />
                <line x1="23" y1="22" x2="1" y2="22" />
                <polyline points="8 6 12 2 16 6" />
              </svg>
              <svg
                id="sunset-icon"
                width="24"
                ref={this.sunsetIconRef}
                height="24"
                viewBox="0 0 24 24"
                display={"none"}
              >
                <path d="M17,18A5,5,0,0,0,7,18" />
                <line x1="12" y1="9" x2="12" y2="2" />
                <line x1="4.22" y1="10.22" x2="5.64" y2="11.64" />
                <line x1="1" y1="18" x2="3" y2="18" />
                <line x1="21" y1="18" x2="23" y2="18" />
                <line x1="18.36" y1="11.64" x2="19.78" y2="10.22" />
                <line x1="23" y1="22" x2="1" y2="22" />
                <polyline points="16 5 12 9 8 5" />
              </svg>
            </svg>
          </Col>
          <Col span={24}>{selected}</Col>
        </Row>
      </>
    );
  }
}

declare let module: Record<string, unknown>;

export default hot(module)(withRouter(MapView));
