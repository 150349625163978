import * as React from "react";
import { createRef } from "react";
import { hot } from "react-hot-loader";
import { withRouter } from "react-router-dom";
import "./MapView.scss";
import { mapbox } from "../../mapbox";
import "mapbox-gl/dist/mapbox-gl.css";
import { Row, Col } from "antd";
import { firestore } from "../../firebase";
import { MapViewState, getDefaultEventState } from "./MapViewState";
import { ExploreMapState } from "./States/ExploreMapState";
import { UserContext } from "../../providers/UserProvider";
import { MapStateContext } from "./States/MapState";
import { ViewEventMapState } from "./States/ViewEventMapState";
import { LoadingMapState } from "./States/LoadingMapState";
import { analytics } from "../../amplitude";
import { appSettings } from "../../appsettings";
import * as moment from "moment";

//https://github.com/firebase/firebaseui-web-react
//https://github.com/alex3165/react-mapbox-gl
class MapView extends React.Component<Record<string, unknown>, MapViewState> {
  public readonly state: MapViewState = {
    isCreatingEvent: false,
    canCreateEvent: false,
    eventState: getDefaultEventState(),
    mapBox: null,
    mapContainerRef: null,
    lng: -117.5357,
    lat: 34.1449,
    zoom: 8.93,
    allEvents: new Map<string, any>(),
  };

  static contextType = UserContext;
  private mapInstanceRef = createRef<HTMLDivElement>();
  private eventCreationZoomMinLevel = 11.7;
  private markersOnScreen = {};
  private markers = {};
  private mapState = new LoadingMapState((a) => {
    this.mapState = a;
    this.setState(this.state);
  });
  private mapMovesEvents = 0;
  private mapClicksEvents = 0;

  constructor(props) {
    super(props);

    this.onMapMove = this.onMapMove.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    this.onMapBoxRender = this.onMapBoxRender.bind(this);
    this.fillMapAfterLoadEvents = this.fillMapAfterLoadEvents.bind(this);
    this.onMapLoad = this.onMapLoad.bind(this);
    this.fillMapAfterLoadEventsFromFile = this.fillMapAfterLoadEventsFromFile.bind(
      this,
    );

    analytics.getInstance().logEvent("GroupEventsMapView");
  }

  private fillMapAfterLoadEventsFromFile(data) {
    function getNextSaturday() {
      let nextSaturday = moment()
        .startOf("isoWeek")
        .add(5, "day")
        .add(10, "hour");
      const now = moment();
      if (nextSaturday < now) {
        nextSaturday = nextSaturday.add(7, "day");
      }
      return nextSaturday;
    }

    for (const doc of data) {
      doc.get = function (a) {
        if (a === "created" || a === "planned") {
          return {
            toDate: function () {
              return doc[a];
            },
          };
        }
        return doc[a];
      };
      doc.created = new Date();
      doc.planned = getNextSaturday().toDate();
      doc.ownerId = "bFCZiTn3vKPjLPp7vrlxJsboXO32";
      this.state.allEvents.set(doc.id, doc);
    }

    this.fillMap();
  }

  private fillMap() {
    const mapBox = this.state.mapBox;

    const features = [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    for (const doc of this.state.allEvents.values()) {
      const points = doc.get("points");
      const lat = points[0].lat;
      const lng = points[0].lng;

      let userId = null;
      if (this.context) {
        userId = this.context.id;
      }

      features.push({
        type: "Feature",
        properties: {
          eventId: doc.id,
          ownerId: doc.get("ownerId"),
          displayType: userId === doc.get("ownerId") ? "Mine" : "Other",
        },
        geometry: {
          type: "Point",
          coordinates: [lng, lat, 0.0],
        },
      });
    }

    if (mapBox.getSource("routes")) {
      mapBox.getSource("routes").setData({
        type: "FeatureCollection",
        features: features,
      });
    } else {
      mapBox.addSource("routes", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: features,
        },
        cluster: true,
        clusterRadius: 80,
        clusterMaxZoom: 14,
      });
    }

    if (!mapBox.getLayer("route_circles")) {
      mapBox.addLayer({
        id: "route_circles",
        type: "circle",
        source: "routes",
        filter: ["!=", "cluster", true],
        paint: {
          "circle-color": [
            "match",
            ["get", "displayType"],
            "Mine",
            "#3887be",
            "Other",
            "#4264fb",
            "#4264fb",
          ],
          "circle-stroke-width": 2,
          "circle-stroke-color": "#ffffff",
          "circle-opacity": 0.8,
          "circle-radius": 12,
        },
      });
    }

    // after the GeoJSON data is loaded, update markers on the screen on every frame
    mapBox.on("render", this.onMapBoxRender);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { eventId } = this.props.match.params;
    if (eventId) {
      this.mapState = ViewEventMapState.fromUrl(eventId, this.state, (a) => {
        this.mapState = a;
        this.setState(this.state);
      });
    } else {
      this.mapState = new ExploreMapState((a) => {
        this.mapState = a;
        this.setState(this.state);
      });
    }
    this.setState(this.state);
  }

  private onMapLoad() {
    firestore
      .collection("events")
      .where("planned", ">", new Date())
      .orderBy("planned")
      .get()
      .then(this.fillMapAfterLoadEvents);

    fetch("/trails.json")
      .then((response) => response.json())
      .then(this.fillMapAfterLoadEventsFromFile);
  }

  private onMapBoxRender() {
    if (!this.state.mapBox.isSourceLoaded("routes")) return;
    this.updateMarkers();
  }

  private createDonutChart(props) {
    const total = props.point_count;
    const fontSize =
      total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
    const r = total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
    const r0 = Math.round(r * 0.6);
    const w = r * 2;

    let html =
      '<div><svg width="' +
      w +
      '" height="' +
      w +
      '" viewbox="0 0 ' +
      w +
      " " +
      w +
      '" text-anchor="middle" style="font: ' +
      fontSize +
      'px sans-serif; display: block">';

    html +=
      '<circle cx="' +
      r +
      '" cy="' +
      r +
      '" r="' +
      r0 +
      '" fill="#3887be" /><text dominant-baseline="central" transform="translate(' +
      r +
      ", " +
      r +
      ')">' +
      total.toLocaleString() +
      "</text></svg></div>";

    const el = document.createElement("div");
    el.innerHTML = html;
    return el.firstChild;
  }

  private updateMarkers() {
    const newMarkers = {};
    const features = this.state.mapBox.querySourceFeatures("routes");

    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    for (let i = 0; i < features.length; i++) {
      const coords = features[i].geometry.coordinates;
      const props = features[i].properties;
      if (!props.cluster) continue;
      const id = props.cluster_id;

      let marker = this.markers[id];
      if (!marker) {
        const el = this.createDonutChart(props);
        marker = this.markers[id] = new mapbox.Marker({
          element: el,
        }).setLngLat(coords);
      }
      newMarkers[id] = marker;

      if (!this.markersOnScreen[id]) {
        marker.addTo(this.state.mapBox);
      }
    }
    // for every marker we've added previously, remove those that are no longer visible
    for (const i in this.markersOnScreen) {
      if (!newMarkers[i]) this.markersOnScreen[i].remove();
    }
    this.markersOnScreen = newMarkers;
  }

  private fillMapAfterLoadEvents(snap) {
    for (const doc of snap.docs) {
      this.state.allEvents.set(doc.id, doc);
    }

    this.fillMap();
  }

  public componentDidMount() {
    const map = new mapbox.Map({
      container: this.mapInstanceRef.current,
      //style: 'mapbox://styles/mapbox/outdoors-v11',
      style: {
        version: 8,
        sources: {
          osm: {
            type: "raster",
            tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
            tileSize: 256,
            attribution:
              'GroupEvents tiles by <a target="_top" rel="noopener" href="https://tile.openstreetmap.org/">OpenStreetMap tile servers</a>, under the <a target="_top" rel="noopener" href="https://operations.osmfoundation.org/policies/tiles/">tile usage policy</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>',
          },
        },
        layers: [
          {
            id: "osm",
            type: "raster",
            source: "osm",
          },
        ],
      },
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
    });

    map.on("move", this.onMapMove);
    map.on("click", this.onMapClick);
    map.on("load", this.onMapLoad);

    map.addControl(new mapbox.NavigationControl());
    map.addControl(new mapbox.ScaleControl({ position: "bottom-right" }));

    this.setState({ mapBox: map });
  }

  public componentWillUnmount() {
    this.state.mapBox.off("move", this.onMapMove);
    this.state.mapBox.off("click", this.onMapClick);
    this.state.mapBox.off("render", this.onMapBoxRender);
    this.state.mapBox.off("load", this.onMapLoad)
  }

  private onMapMove(e) {
    this.mapMovesEvents = this.mapMovesEvents + 1;

    switch (this.mapMovesEvents) {
      case 1:
        analytics.getInstance().logEvent("GroupEventsMapMoved1");
        break;
      case 5:
        analytics.getInstance().logEvent("GroupEventsMapMoved5");
        break;
      case 10:
        analytics.getInstance().logEvent("GroupEventsMapMoved10");
        break;
      case 20:
        analytics.getInstance().logEvent("GroupEventsMapMoved20");
        break;
      case 50:
        analytics.getInstance().logEvent("GroupEventsMapMoved50");
        break;
      case 150:
        analytics.getInstance().logEvent("GroupEventsMapMoved150");
        break;
      case 250:
        analytics.getInstance().logEvent("GroupEventsMapMoved250");
        break;
      case 500:
        analytics.getInstance().logEvent("GroupEventsMapMoved500");
        break;
    }

    this.setState({
      lat: this.state.mapBox.getCenter().lat.toFixed(4),
      lng: this.state.mapBox.getCenter().lng.toFixed(4),
      zoom: this.state.mapBox.getZoom().toFixed(2),
      canCreateEvent: this.eventCreationZoomMinLevel < this.state.zoom,
    });
  }

  private createMapStateContext(): MapStateContext {
    return {
      viewState: this.state,
      setViewState: this.setState.bind(this),
      user: this.context,
      props: this.props,
      triggerStateChange: (newState) => {
        this.mapState = newState;
        this.setState(this.state);
      },
      updateMap: this.fillMap.bind(this),
    };
  }

  private onMapClick(e) {
    if (appSettings.mode === "debug") {
      console.log(e);
      console.log({
        lat: this.state.mapBox.getCenter().lat.toFixed(4),
        lng: this.state.mapBox.getCenter().lng.toFixed(4),
        zoom: this.state.mapBox.getZoom().toFixed(2),
      });
    }

    this.mapClicksEvents = this.mapClicksEvents + 1;
    switch (this.mapMovesEvents) {
      case 1:
        analytics.getInstance().logEvent("GroupEventsMapClicked1");
        break;
      case 5:
        analytics.getInstance().logEvent("GroupEventsMapClicked5");
        break;
      case 10:
        analytics.getInstance().logEvent("GroupEventsMapClicked10");
        break;
      case 15:
        analytics.getInstance().logEvent("GroupEventsMapClicked15");
        break;
      case 20:
        analytics.getInstance().logEvent("GroupEventsMapClicked20");
        break;
      case 50:
        analytics.getInstance().logEvent("GroupEventsMapClicked50");
        break;
      case 100:
        analytics.getInstance().logEvent("GroupEventsMapClicked100");
        break;
    }

    this.mapState = this.mapState.onMapClick(e, this.createMapStateContext());
    this.setState(this.state);
  }

  public render() {
    return (
      <>
        <Row>
          <Col xl={{ span: 18 }} xs={{ span: 24 }}>
            <div className="map-container" ref={this.mapInstanceRef} />
          </Col>
          <Col className="map-sidebar" xl={{ span: 6 }} xs={{ span: 0 }}>
            {this.mapState.render(this.createMapStateContext())}
          </Col>
        </Row>
        <Row>
          <Col className="map-sidebar" xl={{ span: 0 }} xs={{ span: 24 }}>
            {this.mapState.render(this.createMapStateContext())}
          </Col>
        </Row>
      </>
    );
  }
}

declare let module: Record<string, unknown>;

export default hot(module)(withRouter(MapView));
