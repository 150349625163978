import * as React from "react";
import { createRef, useContext, useEffect, useState } from "react";
import { hot } from "react-hot-loader";
import { withRouter } from "react-router-dom";
import "mapbox-gl/dist/mapbox-gl.css";
import { Typography, Row, Col, Button, Spin, Avatar } from "antd";
import { auth, firestore } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { LoginUIContext } from "../providers/LoginUIProvider";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import moment from "moment";

const MyProfileView = () => {
  const [user, loading, error] = useAuthState(auth);
  const [isLoginModalVisible, setIsLoginModalVisible] = useContext(
    LoginUIContext,
  );
  const [profile, setProfile] = useState(null);

  function pleaseLogin() {
    setIsLoginModalVisible(true);
  }

  useEffect(() => {
    if (user === null) {
      return;
    }
    firestore
      .collection("profiles")
      .doc(user.uid)
      .get()
      .then((p) => {
        setProfile(p);
      });
  }, [user]);

  if (!loading && !user) {
    return (
      <div style={{ backgroundColor: "#fff" }}>
        <Row>
          <Col span={24}>&nbsp;</Col>
        </Row>
        <Row>
          <Col span={24}>&nbsp;</Col>
        </Row>
        <Row>
          <Col span={10}></Col>
          <Col span={4}>
            <Button onClick={pleaseLogin} type="primary">
              Log in
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>&nbsp;</Col>
        </Row>
      </div>
    );
  }

  if (!profile) {
    return (
      <div style={{ backgroundColor: "#fff" }}>
        <Row>
          <Col span={24}>&nbsp;</Col>
        </Row>
        <Row>
          <Col span={24}>&nbsp;</Col>
        </Row>
        <Row>
          <Col span={10} />
          <Col span={4}>
            <Spin size="large" />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div style={{ backgroundColor: "#fff" }}>
        <Row>
          <Col span={4} />
          <Col span={16}>&nbsp;</Col>
        </Row>
        <Row>
          <Col span={4} />
          <Col span={2}>
            <Avatar
              size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
              src={profile.get("photoUrl")}
            />
          </Col>
          <Col span={14}>
            <Typography.Title level={3}>
              {" "}
              {profile.get("displayName")}
            </Typography.Title>
            On site since{" "}
            {moment(profile.get("created").toDate()).format("Do MMM YYYY")}
          </Col>
        </Row>
      </div>
    );
  }
};

declare let module: Record<string, unknown>;

export default hot(module)(withRouter(MyProfileView));
