import * as React from "react";
import { hot } from "react-hot-loader";
import { withRouter, Redirect, Link } from "react-router-dom";

import "./FindGroupEventsView.scss";
import { Input, Spin, Alert, Col, Row, Typography, Space } from "antd";
import { useState } from "react";

import { analytics } from "../../../amplitude";

const FindGroupEventsView = () => {
  const [shouldRedirectToMap, setShouldRedirectToMap] = useState(false);
  const [shouldShowProgress, setShouldShowProgress] = useState(false);
  const [searchLocation, setSearchLocation] = useState("");
  const [shouldShowResult, setShouldShowResult] = useState(false);
  const [shouldShowEmailSubmitted, setShouldShowEmailSubmitted] = useState(
    false,
  );

  function onSearch(e) {
    analytics
      .getInstance()
      .logEvent("FindGroupEventsViewSearchClicked", { search: e });
    setShouldShowProgress(true);
    setShouldShowResult(false);
    setSearchLocation(e);
    setShouldShowEmailSubmitted(false);
    setTimeout(function () {
      setShouldShowProgress(false);
      setShouldShowResult(true);
    }, 1500);
  }

  function onSubmitEmail(e) {
    analytics
      .getInstance()
      .logEvent("FindGroupEventsViewEmailSubmitted", { email: e });

    setShouldShowEmailSubmitted(true);
  }

  if (shouldRedirectToMap) {
    return <Redirect to="/" />;
  }

  let emailSubmitted = (
    <>
      <Row>
        <Col className={"text-aligned-center"} offset={1} span={22}>
          You can provide your email below and we let you know when events will
          be available.
        </Col>
      </Row>
      <Row>
        <Col className={"text-aligned-center"} offset={1} span={22}>
          <Space size={"large"} align={"end"} direction="horizontal">
            <Input.Search
              placeholder="Email"
              allowClear
              enterButton="Submit"
              size="large"
              onSearch={onSubmitEmail}
            />
          </Space>
        </Col>
      </Row>
    </>
  );
  if (shouldShowEmailSubmitted) {
    emailSubmitted = (
      <>
        <Row>
          <Col className={"text-aligned-center"} offset={1} span={22}>
            &nbsp;
          </Col>
        </Row>
        <Row>
          <Col className={"text-aligned-center"} offset={1} span={22}>
            Your email has been submitted successfully. Explore all <Link to={"/"}>events</Link>.
          </Col>
        </Row>
      </>
    );
  }

  let result = <></>;
  if (shouldShowResult) {
    result = (
      <>
        <Row>
          <Col className={"text-aligned-center"} offset={1} span={22}>
            &nbsp;
          </Col>
        </Row>
        <Row>
          <Col className={"text-aligned-center"} offset={5} span={14}>
            <Alert
              message={
                <>
                  <Row>
                    <Col className={"text-aligned-center"} offset={1} span={22}>
                      {"Unfortunately, we found nothing for " + searchLocation}
                    </Col>
                  </Row>
                  {emailSubmitted}
                </>
              }
              type="error"
            />
          </Col>
        </Row>
      </>
    );
  }

  let progress = <></>;
  if (shouldShowProgress) {
    progress = (
      <>
        <Row>
          <Col className={"text-aligned-center"} offset={1} span={22}>
            &nbsp;
          </Col>
        </Row>
        <Row>
          <Col className={"text-aligned-center"} offset={1} span={22}>
            <Spin size={"large"} />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <div className={"container"}>
      <Row>
        <Col className={"text-aligned-center"} offset={1} span={22}>
          <Typography.Title level={2}>
            JoinMeOnHike helps you find hiking buddies
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col className={"text-aligned-center"} offset={1} span={22}>
          <Typography.Title level={3}>
            Enter search location to find upcoming hiking events
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col className={"text-aligned-center"} offset={1} span={22}>
          <Space size={"large"} align={"end"} direction="horizontal">
            <Input.Search
              placeholder="enter location"
              allowClear
              enterButton="Search"
              disabled={shouldShowProgress}
              size="large"
              onSearch={onSearch}
            />
          </Space>
        </Col>
      </Row>
      {progress}
      {result}
    </div>
  );
};

declare let module: Record<string, unknown>;

export default hot(module)(withRouter(FindGroupEventsView));
