import { withRouter, Link } from "react-router-dom";
import { MapState, MapStateContext } from "./MapState";
import { ExploreMapState } from "./ExploreMapState";
import { Typography, Row, Col } from "antd";
import * as React from "react";
import moment from "moment";
import { Comment, Tooltip } from "antd";
import ViewAllComments from "./ViewAllComments";
import { analytics } from "../../../amplitude";

function dictToLngLatArray(a) {
  const result = [];
  for (const i in a) {
    result.push([a[i].lng, a[i].lat]);
  }
  return result;
}

export class ViewEventMapState extends MapState {
  eventId: null;
  addRoute: false;

  render(context: MapStateContext): JSX.Element {
    const doc = context.viewState.allEvents.get(this.eventId);

    if (!doc) {
      return (
        <Row>
          <Col span={22} offset={1}>
            <Typography.Title level={2}>
              Can't find this event.
            </Typography.Title>
          </Col>
        </Row>
      );
    }

    const comment = doc.get("description")
      ? doc.get("description")
      : "Please join me for a hike.";

    if (this.addRoute) {
      window.history.pushState(null, null, "#/events/" + this.eventId);

      if (!context.viewState.mapBox.getSource("current-selected-route")) {
        context.viewState.mapBox.addLayer({
          id: "current-selected-route",
          type: "line",
          source: {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: dictToLngLatArray(doc.get("points")),
              },
            },
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#3887be",
            "line-width": 5,
            "line-opacity": 0.75,
          },
        });
      }

      this.addRoute = false;
    }

    const privateMessageUrl = "/profile/" + doc.get("ownerId");

    return (
      <Row>
        <Col span={22} offset={1}>
          <Typography.Title level={2}>{doc.get("summary")}</Typography.Title>
        </Col>
        <Col span={21} offset={1}>
          <Row>
            <Col span={7}>Distance</Col>
            <Col span={7}>Duration</Col>
            <Col span={7}>Date</Col>
          </Row>
          <Row>
            <Col span={7}>{(doc.get("distance") / 1000).toFixed(2)} km</Col>
            <Col span={7}>{moment.duration(Math.floor(doc.get("time") / 60), "minutes").humanize()}</Col>
            <Col span={7}>
              {moment(doc.get("planned").toDate()).format("Do MMM YYYY")}
            </Col>
          </Row>
        </Col>
        <Col
          span={23}
          offset={1}
          style={{
            overflowY: "auto",
            height: "80vh",
          }}
        >
          <Comment
            author={doc.get("ownerDisplayName")}
            avatar={doc.get("ownerPhotoUrl")}
            content={
              <div>
                {comment}
                <br />
                <Link to={privateMessageUrl}>Send private message</Link>
              </div>
            }
            datetime={
              <Tooltip
                title={moment(doc.get("created").toDate()).format(
                  "YYYY-MM-DD HH:mm:ss",
                )}
              >
                <span>{moment(doc.get("created").toDate()).fromNow()}</span>
              </Tooltip>
            }
          />
          <ViewAllComments
            value={{
              viewState: context,
              doc: doc,
            }}
          />
        </Col>
      </Row>
    );
  }

  static fromOnMapClick(
    triggerStateChange,
    context: MapStateContext,
    features,
  ): MapState {
    const mapState = new ViewEventMapState(triggerStateChange);
    mapState.eventId = features[0].properties.eventId;

    analytics.getInstance().logEvent("ViewEvent", { eventId: mapState.eventId });

    const doc = context.viewState.allEvents.get(mapState.eventId);
    if (doc) {
      window.history.pushState(null, null, "#/events/" + mapState.eventId);

      if (!context.viewState.mapBox.getSource("current-selected-route")) {
        context.viewState.mapBox.addLayer({
          id: "current-selected-route",
          type: "line",
          source: {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: dictToLngLatArray(doc.get("points")),
              },
            },
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#3887be",
            "line-width": 5,
            "line-opacity": 0.75,
          },
        });
      }
    }

    return mapState;
  }

  onMapClick(e: any, context: MapStateContext): MapState {
    window.history.pushState(null, null, "#");
    if (context.viewState.mapBox.getSource("current-selected-route")) {
      context.viewState.mapBox.removeLayer("current-selected-route");
      context.viewState.mapBox.removeSource("current-selected-route");
    }
    return ExploreMapState.fromOnMapClick(this.triggerStateChange, context, e);
  }

  static fromUrl(eventId: any, context: MapStateContext, triggerStateChange) {
    analytics.getInstance().logEvent("ViewEvent", { eventId: eventId });
    const mapState = new ViewEventMapState(triggerStateChange);
    mapState.eventId = eventId;
    mapState.addRoute = true;
    return mapState;
  }
}
