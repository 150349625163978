// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const settings = window.appSettings;

export type AppSettings = {
  mode: string;
  amplitudeKey: string;
  placesJsonUrl: string;
  basePath: string;
  version: string;
};

export const appSettings: AppSettings = {
  mode: settings.mode,
  amplitudeKey: settings.amplitudeKey,
  placesJsonUrl: settings.placesJsonUrl,
  basePath: settings.basePath,
  version: settings.version,
};
