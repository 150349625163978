import * as React from "react";
import { hot } from "react-hot-loader";
import { withRouter } from "react-router-dom";
import "mapbox-gl/dist/mapbox-gl.css";
import { Typography, Row, Col, Button } from "antd";

const { Title } = Typography;

type HelpViewState = {};

class HelpView extends React.Component<Record<string, unknown>, HelpViewState> {
  public readonly state: HelpViewState = {};

  constructor(props) {
    super(props);
  }

  public render() {
    return (
      <>
        <Row>
          <Col span={18}>Help</Col>
          <Col span={6}></Col>
        </Row>
      </>
    );
  }
}

declare let module: Record<string, unknown>;

export default hot(module)(withRouter(HelpView));
