// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/antd/dist/antd.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app{font-family:helvetica, arial, sans-serif;padding:2em;border:5px solid red}.app p{background-color:#ff0}.pl-10{padding-left:10px}.pl-5{padding-left:5px}.pl-15{padding-left:15px}div.react-flow__handle{width:12px;height:12px}\n", "",{"version":3,"sources":["webpack://./App.scss"],"names":[],"mappings":"AAGA,KACI,wCAAyC,CACzC,WAAY,CACZ,oBAJc,CAClB,OAKQ,qBAPS,CAQZ,OAID,iBAAkB,CACrB,MAGG,gBAAiB,CACpB,OAGG,iBAAkB,CACrB,uBAGG,UAAW,CACX,WAAY","sourcesContent":["@import '~antd/dist/antd.css';\n$bg-color: yellow;\n$border-color: red;\n.app {\n    font-family: helvetica, arial, sans-serif;\n    padding: 2em;\n    border: 5px solid $border-color;\n    p {\n        background-color: $bg-color;\n    }\n}\n\n.pl-10 {\n    padding-left: 10px;\n}\n\n.pl-5 {\n    padding-left: 5px;\n}\n\n.pl-15 {\n    padding-left: 15px;\n}\n\ndiv.react-flow__handle {\n    width: 12px;\n    height: 12px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
