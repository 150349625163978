import * as React from "react";
import { createContext, useContext } from "react";
import { hot } from "react-hot-loader";
import { auth, firebase, firestore } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useState } from "react";
import { Modal } from "antd";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

export const LoginUIContext = createContext([]);

const LoginUIProvider = (props) => {
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [user, loading, error] = useAuthState(auth);

  const closeLoginModal = () => {
    setIsLoginModalVisible(false);
  };
  const uiConfig = {
    signInFlow: "popup",
    signInSuccessUrl: "/",
    signInOptions: [
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => {
        setIsLoginModalVisible(false);
        return false;
      },
    },
  };

  const tryUpdateProfile = (doc) => {
    if (!doc.exists) {
      firestore.collection("profiles").doc(user.uid).set({
        ownerId: user.uid,
        photoUrl: user.photoURL,
        displayName: user.displayName,
        created: new Date(),
      });
    }
  };

  if (!loading && user !== null) {
    firestore.collection("profiles").doc(user.uid).get().then(tryUpdateProfile);
  }

  if (isLoginModalVisible) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.sendAnalyticEvent("event", "Click", "Login");
  }

  return (
    <LoginUIContext.Provider
      value={[isLoginModalVisible, setIsLoginModalVisible]}
    >
      {/* eslint-disable-next-line react/prop-types */}
      {props.children}
      <Modal
        title="Please create an account to see and explore hiking events."
        visible={isLoginModalVisible}
        onCancel={closeLoginModal}
        footer={[]}
      >
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
      </Modal>
    </LoginUIContext.Provider>
  );
};

export default hot(module)(LoginUIProvider);
