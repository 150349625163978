import * as React from "react";
import { hot } from "react-hot-loader";
import { withRouter, Redirect, Link } from "react-router-dom";

import "./CreateEventFastView.scss";
import { Button, Col, Row, Typography, Space } from "antd";
import { createRef, useState } from "react";

import { analytics } from "../../../amplitude";
import { auth, firestore } from "../../../firebase";
import { mapbox } from "../../../mapbox";
import { UserContext } from "../../../providers/UserProvider";
import * as moment from "moment";
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";

type CreateEventFastViewState = {
  mapBox: any;
  mapContainerRef: HTMLDivElement;
  lng: number;
  lat: number;
  zoom: number;
  showNext: false;
  showFinish: false;
  newEvent: any;
  clickedCoordinates: any;
};

class CreateEventFastView extends React.Component<
  Record<string, unknown>,
  CreateEventFastViewState
> {
  public readonly state: CreateEventFastViewState = {
    mapBox: null,
    mapContainerRef: null,
    lng: -122.44,
    lat: 37.7508,
    zoom: 11.26,
    clickedCoordinates: null,
    showFinish: false,
    showNext: false,
    newEvent: null,
  };

  private mapInstanceRef = createRef<HTMLDivElement>();
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.onMapMove = this.onMapMove.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    this.onMapBoxRender = this.onMapBoxRender.bind(this);
    this.onMapLoad = this.onMapLoad.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onFacebookShareButtonClick = this.onFacebookShareButtonClick.bind(
      this,
    );
    this.onFacebookMessengerShareButtonClick = this.onFacebookMessengerShareButtonClick.bind(
      this,
    );
    this.onWhatsappShareButtonClick = this.onWhatsappShareButtonClick.bind(
      this,
    );
    this.onEmailShareButtonClick = this.onEmailShareButtonClick.bind(this);
  }

  public componentDidMount() {
    const map = new mapbox.Map({
      container: this.mapInstanceRef.current,
      //style: 'mapbox://styles/mapbox/outdoors-v11',
      style: {
        version: 8,
        sources: {
          osm: {
            type: "raster",
            tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
            tileSize: 256,
            attribution:
              'GroupEvents tiles by <a target="_top" rel="noopener" href="https://tile.openstreetmap.org/">OpenStreetMap tile servers</a>, under the <a target="_top" rel="noopener" href="https://operations.osmfoundation.org/policies/tiles/">tile usage policy</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>',
          },
        },
        layers: [
          {
            id: "osm",
            type: "raster",
            source: "osm",
          },
        ],
      },
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
    });

    map.on("move", this.onMapMove);
    map.on("click", this.onMapClick);
    map.on("load", this.onMapLoad);

    map.addControl(new mapbox.NavigationControl());
    map.addControl(new mapbox.ScaleControl({ position: "bottom-right" }));

    this.setState({ mapBox: map });
  }

  private onMapBoxRender() {}

  public componentWillUnmount() {
    this.state.mapBox.off("move", this.onMapMove);
    this.state.mapBox.off("click", this.onMapClick);
    this.state.mapBox.off("render", this.onMapBoxRender);
  }

  private lngLatArrayToDict(a) {
    const result = [];
    for (const i in a) {
      result.push({
        lng: a[i][0],
        lat: a[i][1],
      });
    }
    return result;
  }

  private getNextSaturday() {
    let nextSaturday = moment()
      .startOf("isoWeek")
      .add(5, "day")
      .add(10, "hour");
    const now = moment();
    if (nextSaturday < now) {
      nextSaturday = nextSaturday.add(7, "day");
    }
    return nextSaturday;
  }

  private async onNextClick(e) {
    const coordinates = this.lngLatArrayToDict([this.state.clickedCoordinates]);
    const obj = {
      created: new Date(),
      summary: "",
      geohash: "",
      planned: this.getNextSaturday().toDate(),
      ownerId: this.context.uid,
      ownerPhotoUrl: this.context.photoURL,
      ownerDisplayName: this.context.displayName,
      distance: 0,
      time: 0,
      description: "",
      points: coordinates,
    };
    const doc = await firestore.collection("events").add(obj);
    const a = await firestore.collection("events").doc(doc.id).get();
    this.setState({ showNext: false, showFinish: true, newEvent: a });
    analytics.getInstance().logEvent("GroupEventCreated", obj);
  }

  private onMapLoad() {
    this.state.mapBox.addSource("selected-point-source", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Point",
              coordinates: [],
            },
          },
        ],
      },
    });
    this.state.mapBox.addLayer({
      id: "selected-point-layer",
      type: "circle",
      source: "selected-point-source",
      paint: {
        "circle-color": "#4264fb",
        "circle-radius": 8,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#ffffff",
      },
    });
  }

  private onMapClick(e) {
    const lngLat = e.lngLat;
    const coords = Object.keys(lngLat).map(function (key) {
      return lngLat[key];
    });

    this.setState({ clickedCoordinates: coords, showNext: true });

    const current = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: coords,
          },
        },
      ],
    };
    this.state.mapBox.getSource("selected-point-source").setData(current);
  }

  private onMapMove(e) {}

  private onEmailShareButtonClick(e) {
    analytics.getInstance().logEvent("EmailShareButtonClicked");
  }

  private onWhatsappShareButtonClick(e) {
    analytics.getInstance().logEvent("WhatsappShareButtonClicked");
  }
  private onFacebookMessengerShareButtonClick(e) {
    analytics.getInstance().logEvent("FacebookMessengerShareButtonClicked");
  }
  private onFacebookShareButtonClick(e) {
    analytics.getInstance().logEvent("FacebookShareButtonClicked");
  }

  public render() {
    let next = <></>;
    if (this.state.showNext) {
      next = (
        <Row>
          <Col className={"text-aligned-center"} offset={1} span={22}>
            When you are ready press next &nbsp;&nbsp;&nbsp;&nbsp;
            <Button type="primary" onClick={this.onNextClick}>
              Next
            </Button>
          </Col>
        </Row>
      );
    }

    let finish = <></>;
    if (this.state.showFinish) {
      const eventUrl =
        "https://joinmeonhike.com/#/events/" + this.state.newEvent.id;
      const quote = "Join me on my next hike! @joinmeonhike";
      finish = (
        <>
          <Row>
            <Col className={"text-aligned-center"} offset={1} span={22}>
              Well done! You've just created your first group hike. Now it's
              time to share your hike with friends.
              <br />
              <Link to={"events/" + this.state.newEvent.id}>{eventUrl}</Link>
            </Col>
          </Row>
          <Row>
            <Col className={"text-aligned-center"} offset={1} span={22}>
              Or
            </Col>
          </Row>
          <Row>
            <Col className={"text-aligned-center"} offset={1} span={22}>
              Explore other <Link to={"/"}>events</Link>
            </Col>
          </Row>
          <Row>
            <Col>&nbsp;</Col>
          </Row>
          <Row>
            <Col offset={12} span={12}>
              <FacebookShareButton
                url={eventUrl}
                quote={quote}
                onClick={this.onFacebookShareButtonClick}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              &nbsp;
              <FacebookMessengerShareButton
                url={eventUrl}
                appId="146284127425525"
                onClick={this.onFacebookMessengerShareButtonClick}
              >
                <FacebookMessengerIcon size={32} round />
              </FacebookMessengerShareButton>
              &nbsp;
              <WhatsappShareButton
                url={eventUrl}
                title={quote}
                onClick={this.onWhatsappShareButtonClick}
                separator=":: "
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              &nbsp;
              <EmailShareButton
                url={eventUrl}
                subject={quote}
                onClick={this.onEmailShareButtonClick}
                body=""
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </Col>
          </Row>
        </>
      );
    }

    return (
      <div className={"container"}>
        <Row>
          <Col className={"text-aligned-center"} offset={1} span={22}>
            <Typography.Title level={2}>
              Mark a start point for your next hike
            </Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div
              className="create-event-fast-map-container"
              ref={this.mapInstanceRef}
            />
          </Col>
        </Row>
        <Row>
          <Col className={"text-aligned-center"} offset={1} span={22}>
            &nbsp;
          </Col>
        </Row>
        {next}
        {finish}
      </div>
    );
  }
}

declare let module: Record<string, unknown>;

export default hot(module)(withRouter(CreateEventFastView));
