import * as React from "react";
import { mapbox } from "../../../mapbox";
import "mapbox-gl/dist/mapbox-gl.css";
import { Typography } from "antd";
import CreateEvent from "./CreateEvent";

import {
  getDefaultEventState,
} from "../MapViewState";
import { MapState, MapStateContext } from "./MapState";
import { ExploreMapState } from "./ExploreMapState";
import { ViewEventMapState } from "./ViewEventMapState";

const { Title } = Typography;
export class CreateEventMapState extends MapState {
  constructor(triggerStateChange) {
    super(triggerStateChange);
  }

  render(context: MapStateContext): JSX.Element {
    function onCancelEventCreationHandler() {
      context.viewState.eventState = getDefaultEventState();
      context.viewState.isCreatingEvent = false;

      if (context.viewState.mapBox.getSource("route")) {
        context.viewState.mapBox.removeLayer("route");
        context.viewState.mapBox.removeSource("route");
      }

      if (context.viewState.mapBox.getSource("start")) {
        context.viewState.mapBox.removeLayer("start");
        context.viewState.mapBox.removeSource("start");
      }

      if (context.viewState.mapBox.getSource("end")) {
        context.viewState.mapBox.removeLayer("end");
        context.viewState.mapBox.removeSource("end");
      }

      this.triggerStateChange(new ExploreMapState(this.triggerStateChange));
    }

    function onSaveEventHandler(doc) {
      context.setViewState({
        isCreatingEvent: false,
        eventState: getDefaultEventState(),
      });

      context.viewState.mapBox.removeLayer("start");
      context.viewState.mapBox.removeSource("start");

      context.viewState.mapBox.removeLayer("end");
      context.viewState.mapBox.removeSource("end");

      context.viewState.mapBox.removeLayer("route");
      context.viewState.mapBox.removeSource("route");

      context.viewState.allEvents.set(doc.id, doc);

      context.updateMap();

      this.triggerStateChange(
        ViewEventMapState.fromUrl(doc.id, this.triggerStateChange),
      );
    }

    return (
      <CreateEvent
        value={{
          isCreatingEvent: context.viewState.isCreatingEvent,
          canCreateEvent: context.viewState.canCreateEvent,
          eventState: context.viewState.eventState,
          cancelEventCreationHandler: onCancelEventCreationHandler.bind(this),
          saveEventHandler: onSaveEventHandler.bind(this),
        }}
      />
    );
  }

  onMapClick(e: any, context: MapStateContext): MapState {
    this.addRouteWaypoint(context.viewState, context.setViewState, e.lngLat);
    return this;
  }

  static fromOnMapClick(
    triggerStateChange,
    context: MapStateContext,
    e: any,
  ): MapState {
    const mapState = new CreateEventMapState(triggerStateChange);
    mapState.onMapClick(e, context);
    return mapState;
  }

  private addRouteWaypoint(viewState, setViewState, lngLat) {
    const map = viewState.mapBox;
    if (this.eventCreationZoomMinLevel < viewState.mapBox.getZoom()) {
      if (!viewState.isCreatingEvent) {
        setViewState({
          isCreatingEvent: true,
          eventState: getDefaultEventState(),
        });
      }

      const coords = this.lngLatDictToArray(lngLat);
      map.getCanvasContainer().style.cursor = "";

      if (map.getLayer("end")) {
        const end = this.getFeatureCollectionDescription(coords);
        map.getSource("end").setData(end);
      } else {
        map.addLayer(this.getMapLayerDescription("start", coords, "#f30"));
        map.addLayer(this.getMapLayerDescription("end", [], "#3887be"));
      }
      this.getRoute(coords, viewState, setViewState);
    }
  }

  private getRoute(end, viewState, setViewState) {
    viewState.eventState.clickCoordinates.push(end[0]);
    viewState.eventState.clickCoordinates.push(end[1]);

    const clicksCount = viewState.eventState.clickCoordinates.length;
    const prevPoint = clicksCount == 2 ? clicksCount - 1 : clicksCount - 3;

    // make directions request using cycling profile
    const url =
      "https://api.mapbox.com/directions/v5/mapbox/walking/" +
      viewState.eventState.clickCoordinates[prevPoint - 1] +
      "," +
      viewState.eventState.clickCoordinates[prevPoint] +
      ";" +
      end[0] +
      "," +
      end[1] +
      "?steps=true&geometries=geojson&access_token=" +
      mapbox.accessToken;

    // make an XHR request https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.onload = function (e) {
      const json = JSON.parse(e.target.response);
      const data = json.routes[0];
      const route = data.geometry.coordinates;

      for (const r in route) {
        viewState.eventState.routeCoordinates.push(route[r]);
      }

      const geojson = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: viewState.eventState.routeCoordinates,
        },
      };

      // if the route already exists on the map, we'll reset it using setData
      if (viewState.mapBox.getSource("route")) {
        viewState.mapBox.getSource("route").setData(geojson);
      }
      // otherwise, we'll make a new request
      else {
        viewState.mapBox.addLayer({
          id: "route",
          type: "line",
          source: {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: viewState.eventState.routeCoordinates,
              },
            },
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#3887be",
            "line-width": 5,
            "line-opacity": 0.75,
          },
        });
      }

      viewState.eventState.clicks += 1;
      viewState.eventState.routeInstructions.set(
        viewState.eventState.clicks,
        data.legs[0],
      );
      viewState.eventState.durations.push(data.duration);

      for (const [key, instruction] of viewState.eventState.routeInstructions) {
        if (instruction.summary !== "") {
          viewState.eventState.summary = instruction.summary;
          break;
        }
      }

      let distance = 0;
      for (const [key, instruction] of viewState.eventState.routeInstructions) {
        distance += instruction.distance;
      }
      viewState.eventState.distance = distance;

      setViewState({ eventState: viewState.eventState });
    };
    req.send();
  }

  private getMapLayerDescription(name, coords, color) {
    return {
      id: name,
      type: "circle",
      source: {
        type: "geojson",
        data: this.getFeatureCollectionDescription(coords),
      },
      paint: {
        "circle-radius": 10,
        "circle-color": color,
      },
    };
  }

  private lngLatDictToArray(coordsObj) {
    const coords = Object.keys(coordsObj).map(function (key) {
      return coordsObj[key];
    });
    return coords;
  }

  private getFeatureCollectionDescription(coords) {
    return {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: coords,
          },
        },
      ],
    };
  }
}
