import * as React from "react";
import * as _ from "lodash";
import { withRouter, Redirect, Link } from "react-router-dom";
import { hot } from "react-hot-loader";
import { Button, Layout } from "antd";
import { auth, firebase, firestore } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useState, useContext, useEffect } from "react";
import { LoginUIContext } from "../providers/LoginUIProvider";
import { analytics } from "../amplitude";

const Menu = () => {
  const [user, loading, error] = useAuthState(auth);
  const [isLoginModalVisible, setIsLoginModalVisible] = useContext(
    LoginUIContext,
  );
  const loginClick = () => {
    setIsLoginModalVisible(true);
  };


  const logoutClick = () => {
    auth.signOut();
  };

  let loginLink = (
    <Button type="link" onClick={loginClick}>
      Login
    </Button>
  );

  if (!loading && user !== null) {
    analytics.getInstance().setUserId(user.uid);
    analytics.getInstance().logEvent("UserLogin");
    loginLink = (
      <>
        {/*<Link to={"/profile"}>Profile</Link>*/}
        <Button type="link" onClick={logoutClick}>
          Logout
        </Button>
      </>
    );
  }

  let menu = (
    <>
      <Link to={"/"}>Events</Link>&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/meditation-places"}>Meditations</Link>&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/download"}>Download</Link>&nbsp;&nbsp;&nbsp;&nbsp;
      {/*<Link to={"/help"}>Help</Link>&nbsp;*/}
      {loginLink}
    </>
  );
  if (loading) {
    menu = <></>;
  }

  return <>{menu}</>;
};

export default hot(module)(withRouter(Menu));
