import * as React from "react";
import { hot } from "react-hot-loader";
import { withRouter, Redirect } from "react-router-dom";

import "./LoginLandingView.scss";
import { Col, Row, Typography } from "antd";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, firebase } from "../../../firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

const LoginLandingView = () => {
  const [user, loading, error] = useAuthState(auth);

  const uiConfig = {
    signInFlow: "popup",
    signInSuccessUrl: "/",
    signInOptions: [
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => {
        return false;
      },
    },
  };

  if (user && !loading) {
    return <Redirect to="/create-event-fast" />;
  }

  return (
    <div className={"container"}>
      <Row>
        <Col className={"text-aligned-center"} offset={1} span={22}>
          <Typography.Title level={2}>
            But before we continue please sign in
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col className={"text-aligned-center"} offset={1} span={22}>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </Col>
      </Row>
    </div>
  );
};

declare let module: Record<string, unknown>;

export default hot(module)(withRouter(LoginLandingView));
