import * as React from "react";
import { hot } from "react-hot-loader";
import { withRouter, Redirect } from "react-router-dom";

import "./SurveyView.scss";
import { Button, Col, Radio, Row, Progress, Typography, Space } from "antd";
import { useState } from "react";

import { analytics } from "../amplitude";

const SurveyView = () => {
  const [shouldRedirectToMap, setShouldRedirectToMap] = useState(false);
  const [shouldRedirectToFinish, setShouldRedirectToFinish] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);

  const answers = [];

  const questions = [
    {
      title: "Are you looking for a hiking group?",
      options: ["Yes", "No"],
      id: 1,
    },
    {
      title: "How often do you go hiking?",
      options: [
        "Every weekend",
        "Once a month",
        "Once in 3 month",
        "Couple of times a year",
        "Never",
      ],
      id: 2,
    },
    {
      title: "Are you going to go hiking in the next 2 weeks?",
      options: ["Yes", "No"],
      id: 3,
    },
  ];

  function nextClick() {
    analytics.getInstance().logEvent("SurveyNextClicked");
    if (questionIndex === questions.length - 1) {
      setShouldRedirectToFinish(true);
    } else {
      setQuestionIndex(questionIndex + 1);
    }
  }

  function logAnswer(e) {
    const answer = {
      question: questions[questionIndex].title,
      answer: e.target.value,
      id: questions[questionIndex].id,
    };
    analytics.getInstance().logEvent("SurveyAnswer", answer);
    answers.push({ id: answer.id, answer: answer.answer });
  }

  function skipClick() {
    analytics.getInstance().logEvent("SurveySkipClicked");
    setShouldRedirectToMap(true);
  }

  if (shouldRedirectToMap) {
    return <Redirect to="/" />;
  }

  if (shouldRedirectToFinish) {
    return <Redirect to="/finish-survey" />;
  }

  return (
    <div className={"container"}>
      <Row>
        <Col offset={1} span={22}>
          <Progress
            showInfo={false}
            percent={(questionIndex / questions.length) * 100}
          />
        </Col>
      </Row>
      <Row>
        <Col offset={1} span={22}>
          <Typography.Title title={"2"}>
            {questions[questionIndex].title}
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col offset={1} span={22}>
          <Radio.Group onChange={logAnswer}>
            <Space direction="vertical">
              {questions[questionIndex].options.map((a, i) => {
                return (
                  <Radio key={"radio-" + i} value={a}>
                    {a}
                  </Radio>
                );
              })}
            </Space>
          </Radio.Group>
        </Col>
      </Row>
      <Row>
        <Col offset={1} span={22}>
          &nbsp;
        </Col>
      </Row>
      <Row>
        <Col offset={1} span={10}>
          <Space size={"large"} align={"end"} direction="horizontal">
            <Button type="link" onClick={skipClick}>
              Skip
            </Button>
            <div>&nbsp;</div>
            <Button type="primary" onClick={nextClick}>
              Next
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

declare let module: Record<string, unknown>;

export default hot(module)(withRouter(SurveyView));
