import * as React from "react";
import { hot } from "react-hot-loader";
import { withRouter, Link, Redirect } from "react-router-dom";

import "./FinishSurveyView.scss";
import { Button, Col, Row, Progress, Typography } from "antd";
import { useState } from "react";

import { analytics } from "../amplitude";

import { Result } from "antd";
import { SmileOutlined } from "@ant-design/icons";

const FinishSurveyView = () => {
  const [shouldRedirectToMap, setShouldRedirectToMap] = useState(false);

  function nextClick() {
    setShouldRedirectToMap(true);
    analytics.getInstance().logEvent("FinishSurveyNextClicked");
  }

  if (shouldRedirectToMap) {
    return <Redirect to="/" />;
  }

  return (
    <div className={"container"}>
      <Row>
        <Col offset={1} span={22}>
          <Result
            icon={<SmileOutlined />}
            title="Thanks for the answers! Now you are ready to explore the map."
            extra={<Button onClick={nextClick} type="primary">Show the map</Button>}
          />
        </Col>
      </Row>
    </div>
  );
};

declare let module: Record<string, unknown>;

export default hot(module)(withRouter(FinishSurveyView));
