import * as React from "react";
import { render } from "react-dom";
import { HashRouter } from "react-router-dom";
import App from "./App";
import { analytics } from "./amplitude";
import { appSettings } from "./appsettings";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

if(appSettings.mode === "production") {
  Sentry.init({
    release: appSettings.mode + "@" + appSettings.version,
    dsn:
      "https://7e26730be3394d57b8adf76f7291e5bb@o914304.ingest.sentry.io/5852979",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

function parseQuery(url) {
  const query = { url: url };
  const questionIndex = url.indexOf("?");
  const hashIndex = url.indexOf("#");
  const pairs = [];

  let hash = "";
  let queryParams = "";

  if (hashIndex === -1 || questionIndex === -1) {
    if (hashIndex === -1 && questionIndex === -1) {
      hash = "#/";
      queryParams = "";
    } else if (hashIndex !== -1 && questionIndex === -1) {
      queryParams = "";
      hash = url.substr(hashIndex);
    } else if (questionIndex !== -1 && hashIndex === -1) {
      hash = "#/";
      queryParams = url.substr(questionIndex);
    }
  } else {
    if (hashIndex < questionIndex) {
      hash = url.substr(hashIndex, questionIndex - hashIndex);
      queryParams = url.substr(questionIndex);
    } else {
      hash = url.substr(hashIndex);
      queryParams = url.substr(questionIndex, hashIndex - questionIndex);
    }
  }
  pairs.push("hash=" + hash);
  const queryParamsArray = queryParams.split(/[?&]/);
  for (const i in queryParamsArray) {
    if (queryParamsArray[i]) {
      pairs.push(queryParamsArray[i]);
    }
  }
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}

analytics.getInstance().logEvent("AppLoaded", parseQuery(window.location.href));

window.onerror = function (msg, url, lineNo, columnNo, error) {
  analytics
    .getInstance()
    .logEvent("ErrorOccurred", { msg, url, lineNo, columnNo, error });
  return false;
};

const rootEl = document.getElementById("root");

render(
  <HashRouter basename={rootEl.dataset.baseUrl}>
    <App />
  </HashRouter>,
  rootEl,
);
