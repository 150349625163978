import firebaseApp from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
firebaseApp.initializeApp(window.firebaseConfig);

const provider = new firebaseApp.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
  auth.signInWithPopup(provider);
};

export const firebase = firebaseApp;
export const auth = firebaseApp.auth();
export const firestore = firebaseApp.firestore();
