import * as React from "react";
import { Component, createContext } from "react";
import { auth } from "../firebase";
import { hot } from "react-hot-loader";

export const UserContext = createContext({ user: null });

class UserProvider extends Component {
  state = {
    user: null,
  };

  componentDidMount = () => {
    auth.onAuthStateChanged((userAuth) => {
      this.setState({ user: userAuth });
    });
  };

  public render() {
    return (
      <UserContext.Provider value={this.state.user}>
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

declare let module: Record<string, unknown>;
export default hot(module)(UserProvider);
