import {
  MapViewState,
  EventState,
  getDefaultEventState,
} from "../MapViewState";

export type MapStateContext = {
  viewState: MapViewState;
  setViewState: any;
  user: any;
  triggerStateChange: any;
  props: any;
  updateMap: any;
};

export abstract class MapState {
  protected eventCreationZoomMinLevel = 11.7;
  protected triggerStateChange;

  constructor(triggerStateChange) {
    this.triggerStateChange = triggerStateChange;
  }

  abstract onMapClick(e, context: MapStateContext): MapState;
  abstract render(context: MapStateContext): JSX.Element;
}
