import * as React from "react";
import { withRouter, Redirect, Link } from "react-router-dom";
import { hot } from "react-hot-loader";
import { Button, Layout } from "antd";
const { Content, Footer, Header } = Layout;
import Menu from "../components/Menu";

const Builder = (props) => {
  const { to, staticContext, children, ...rest } = props;
  return (
    <Layout>
      <Header>
        <Menu />
      </Header>
      <Content>{children}</Content>
    </Layout>
  );
};

declare let module: Record<string, unknown>;
export default hot(module)(withRouter(Builder));
