import * as React from "react";
import { hot } from "react-hot-loader";
import { withRouter, Redirect, Link } from "react-router-dom";

import "./ChatBotView.scss";
import { Image, Input, Spin, Alert, Col, Row, Typography, Space } from "antd";
import { useContext, useState, useEffect } from "react";
//import * as zipcodes from "zipcodes";

import { analytics, analyticsWrapper } from "../../../amplitude";

//https://lucasbassetti.com.br/react-simple-chatbot/#/docs/installation
import ChatBot from "react-simple-chatbot";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MapExample from "./imgs/map-example.png";

import styled from "styled-components";

const Header = styled.div`
  align-items: center;
  background: #6e48aa;
  color: #fff;
  display: flex;
  fill: #fff;
  height: 56px;
  justify-content: space-between;
  padding: 0 10px;
`;

const HeaderTitle = styled.h2`
  margin: 0;
  font-size: 16px;
  color: #fff;
`;

const HeaderIcon = styled.a`
  cursor: pointer;
`;

const CloseIcon = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12
      13.41 17.59 19 19 17.59 13.41 12z"
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

const ChatBotView = () => {
  const [shouldRedirectToMap, setShouldRedirectToMap] = useState(false);
  const steps = [
    {
      message:
        "Hi! I'm your personal virtual assistant. In my database, I have more than 100 group hikes for the upcoming week.",
      trigger: onNextStepTrigger,
    },
    {
      message:
        "I'm ready to help you find your next perfect hike. After a few questions, I'll identify your profile and find matches. Do you want to continue?",
      trigger: onNextStepTrigger,
      delay: 4000,
    },
    {
      options: [
        {
          value: "Yes",
          label: "Yes",
          trigger: onNextStepTrigger,
        },
        {
          value: "No",
          label: "No",
          trigger: onNextStepTrigger,
        },
      ],
    },
    {
      message: "What is your name?",
      trigger: onNextStepTrigger,
    },
    {
      user: true,
      trigger: onNextStepTrigger,
    },
    {
      message: "Hi {previousValue}, nice to meet you!",
      trigger: onNextStepTrigger,
    },
    {
      message: "When are you planning to hike?",
      trigger: onNextStepTrigger,
    },
    {
      options: [
        {
          value: "ThisMonth",
          label: "This month",
          trigger: onNextStepTrigger,
        },
        {
          value: "NextMonth",
          label: "Next month",
          trigger: onNextStepTrigger,
        },
        {
          value: "Later",
          label: "Later",
          trigger: onNextStepTrigger,
        },
      ],
    },
    {
      message: "Why do you hike?",
      trigger: onNextStepTrigger,
    },
    {
      user: true,
      trigger: onNextStepTrigger,
    },
    {
      message: "Where do you like to hike?",
      trigger: onNextStepTrigger,
    },
    {
      options: [
        {
          value: "Forests",
          label: "Forests",
          trigger: onNextStepTrigger,
        },
        {
          value: "Mountains",
          label: "Mountains",
          trigger: onNextStepTrigger,
        },
        {
          value: "Lakes",
          label: "Lakes",
          trigger: onNextStepTrigger,
        },
        {
          value: "Hills",
          label: "Hills",
          trigger: onNextStepTrigger,
        },
        {
          value: "Rivers",
          label: "Rivers",
          trigger: onNextStepTrigger,
        },
        {
          value: "Beaches",
          label: "Beaches",
          trigger: onNextStepTrigger,
        },
      ],
    },
    {
      message: "Where do you like to start your hikes, please enter location?",
      trigger: onNextStepTrigger,
    },
    {
      user: true,
      trigger: onNextStepTrigger,
    },
    {
      message:
        "While we are searching, I'll provide you with a quick introduction of our platform.",
      trigger: onNextStepTrigger,
      delay: 2500,
    },
    {
      component: (
        <>
          <Image width={200} src={MapExample} />
        </>
      ),
      trigger: onNextStepTrigger,
      delay: 3500,
    },
    {
      message:
        "On the map we show blue markers with upcoming hiking events. When you click on the marker you will see details of the event.",
      trigger: onNextStepTrigger,
      delay: 4500,
    },
    {
      message:
        "We are still searching... Please provide your email for notifications.",
      trigger: onNextStepTrigger,
    },
    {
      user: true,
      trigger: onNextStepTrigger,
    },
    {
      message:
        "Unfortunately, we can't find any hikes for you. We will redirect you to the map for manual exploration. Buy!",
      trigger: redirectToMap,
      delay: 4000,
    },
    {
      message: "In 5 seconds we will redirect you to the map.",
      end: true,
    },
  ];

  let id = 1;
  for (const step in steps) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    steps[step].id = "" + id;
    id = id + 1;
  }

  useEffect(() => {
    analyticsWrapper.logEvent("ChatBot11July2021VersionStarted", {});
  }, []);

  function onNextStepTrigger(e) {
    let lastStepName = "";
    let previousStepName;
    for (const i in e.steps) {
      previousStepName = lastStepName;
      lastStepName = i;
    }
    analyticsWrapper.logEvent(
      "ChatBotStep" + e.steps[lastStepName].id + "Triggered",
      {
        step: e.steps[lastStepName],
        value: e.value,
        previousStep: e.steps[previousStepName],
      },
    );

    if (lastStepName === "3" && e.value === "No") {
      analyticsWrapper.logEvent("ChatBotSkipClicked", {
        target: "NoAnswer",
      });
      setShouldRedirectToMap(true);
    }

    const nextStepId = parseInt(e.steps[lastStepName].id) + 1 + "";
    return nextStepId;
  }

  function redirectToMap(e) {
    onNextStepTrigger(e);
    setTimeout(function () {
      setShouldRedirectToMap(true);
    }, 6000);
  }

  function onClosedIconClicked() {
    analyticsWrapper.logEvent("ChatBotSkipClicked", {
      target: "CloseIcon",
    });
    setShouldRedirectToMap(true);
  }

  if (shouldRedirectToMap) {
    return <Redirect to="/" />;
  }

  return (
    <div className={"container"}>
      <Row>
        <Col style={{ margin: "0 auto" }}>
          <ChatBot
            headerTitle={"JoinMeOnHike.com"}
            headerComponent={
              <Header className="rsc-header">
                <HeaderTitle className="rsc-header-title">
                  JoinMeOnHike.com
                </HeaderTitle>
                <HeaderIcon
                  className="rsc-header-close-button"
                  onClick={onClosedIconClicked}
                >
                  <CloseIcon />
                </HeaderIcon>
              </Header>
            }
            steps={steps}
          />
        </Col>
      </Row>
    </div>
  );
};

declare let module: Record<string, unknown>;

export default hot(module)(withRouter(ChatBotView));
