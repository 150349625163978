import * as React from "react";
import { withRouter } from "react-router-dom";
import { hot } from "react-hot-loader";
import { Typography, Row, Col, Button, DatePicker } from "antd";
import { useAuthState } from "react-firebase-hooks/auth";
import { useContext, useState } from "react";
import { auth, firestore } from "../../../firebase";
import "mapbox-gl/dist/mapbox-gl.css";
import { LoginUIContext } from "../../../providers/LoginUIProvider";
import { geohashForLocation } from "geofire-common";
import * as moment from "moment";
import { Input } from "antd";
import { Form, Radio } from "antd";
import { analytics } from "../../../amplitude";

const { TextArea } = Input;

function getNextSaturday() {
  let nextSaturday = moment().startOf("isoWeek").add(5, "day").add(10, "hour");
  const now = moment();
  if (nextSaturday < now) {
    nextSaturday = nextSaturday.add(7, "day");
  }
  return nextSaturday;
}

const CreateEvent = ({ history, location, match, value }) => {
  const [user, loading, error] = useAuthState(auth);
  const [isLoginModalVisible, setIsLoginModalVisible] = useContext(
    LoginUIContext,
  );
  const [form] = Form.useForm();
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(getNextSaturday());

  function lngLatArrayToDict(a) {
    const result = [];
    for (const i in a) {
      result.push({
        lng: a[i][0],
        lat: a[i][1],
      });
    }
    return result;
  }

  async function saveEventHandler() {
    if (!loading && user) {
      let duration = 0;
      for (const i in value.eventState.durations) {
        duration += value.eventState.durations[i];
      }

      const coordinates = lngLatArrayToDict(value.eventState.routeCoordinates);
      const hash = geohashForLocation([coordinates[0].lat, coordinates[0].lng]);
      const obj = {
        created: new Date(),
        summary: value.eventState.summary,
        geohash: hash,
        planned: date.toDate(),
        ownerId: user.uid,
        ownerPhotoUrl: user.photoURL,
        ownerDisplayName: user.displayName,
        distance: value.eventState.distance,
        time: duration,
        description: description,
        points: coordinates,
      };
      const doc = await firestore.collection("events").add(obj);
      const a = await firestore.collection("events").doc(doc.id).get();
      value.saveEventHandler(a);
      analytics.getInstance().logEvent("GroupEventCreated", obj);
    } else {
      setIsLoginModalVisible(true);
    }
  }

  let duration = 0;
  for (const i in value.eventState.durations) {
    duration += value.eventState.durations[i];
  }

  const formItemLayout = null;

  const onFormUpdate = ({ date, description }) => {
    if (date) {
      setDate(date);
    }
    if (description) {
      setDescription(description);
    }
  };

  let body = <></>;
  body = (
    <Row>
      <Col span={22} offset={1}>
        <Typography.Title level={2}>
          {value.eventState.summary}
        </Typography.Title>
      </Col>
      <Col span={20} offset={1}>
        <Row>
          <Col span={10}>Distance</Col>
          <Col span={10}>Duration</Col>
        </Row>
        <Row>
          <Col span={10}>
            {(value.eventState.distance / 1000).toFixed(2)} km
          </Col>
          <Col span={10}>{Math.floor(duration / 60)} minutes</Col>
        </Row>
      </Col>
      <Col span={20} offset={1}>
        <Form
          {...formItemLayout}
          layout={"vertical"}
          initialValues={{ date: date, description: description }}
          form={form}
          onValuesChange={onFormUpdate}
        >
          <Form.Item label="Date" name="date">
            <DatePicker
              format="YYYY-MM-DD HH:mm"
              showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
            />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Col>
      <Col span={20} offset={1}>
        <Button onClick={value.cancelEventCreationHandler}>Cancel</Button>&nbsp;
        <Button onClick={saveEventHandler}>Save</Button>
      </Col>
    </Row>
  );

  return <>{body}</>;
};

export default hot(module)(withRouter(CreateEvent));
