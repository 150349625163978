import * as React from "react";
import * as _ from "lodash";
import { withRouter } from "react-router-dom";
import { hot } from "react-hot-loader";
import { Typography, Row, Col, Button, DatePicker, Avatar } from "antd";
import { useAuthState } from "react-firebase-hooks/auth";
import { useContext, useState, useEffect } from "react";
import { auth, firestore } from "../../../firebase";
import firebaseApp from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "mapbox-gl/dist/mapbox-gl.css";
import { LoginUIContext } from "../../../providers/LoginUIProvider";
import { geohashForLocation } from "geofire-common";
import moment from "moment";
import { Input } from "antd";
import { Form, Spin } from "antd";
import { Comment, Tooltip, List } from "antd";
import { FacebookProvider, CommentsCount, Comments } from "react-facebook";

const { TextArea } = Input;

const ViewAllComments = ({ history, location, match, value }) => {
  const [user, loading, error] = useAuthState(auth);
  const [isLoginModalVisible, setIsLoginModalVisible] = useContext(
    LoginUIContext,
  );
  const [comments, setComments] = useState([]);

  function pleaseLogin() {
    setIsLoginModalVisible(true);
  }

  if (loading) {
    return (
      <>
        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <Spin size={"large"}></Spin>
          </Col>
        </Row>
      </>
    );
  }

  if (!loading && !user) {
    return (
      <>
        <Row>
          <Col span={23} style={{display: "flex"}}>
            <FacebookProvider appId="746596102691405">
              Please log in to see all&nbsp;
              <CommentsCount href={window.location.href} />
              &nbsp;comments.
            </FacebookProvider>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            &nbsp;
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Button onClick={pleaseLogin} type="primary">
              Log in
            </Button>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <>
        <Row>
          <Col span={24}>
            <FacebookProvider appId="746596102691405">
              <Comments width={380} href={window.location.href} />
            </FacebookProvider>
          </Col>
        </Row>
      </>
    );
  }
};

export default hot(module)(withRouter(ViewAllComments));
