import * as React from "react";
import { hot } from "react-hot-loader";
import { Route, Switch, Link, useHistory } from "react-router-dom";
import MainLayout from "./layout/MainLayout";

import "./App.scss";

import UserProvider from "./providers/UserProvider";
import LoginUIProvider from "./providers/LoginUIProvider";

import MapView from "./views/GroupEvents/MapView";
import MyProfileView from "./views/MyProfileView";
import HelpView from "./views/HelpView";
import OtherProfileView from "./views/OtherProfileView";
import SurveyView from "./views/SurveyView";
import FinishSurveyView from "./views/FinishSurveyView";
import MeditationMapView from "./views/Meditations/MapView";
import DownloadView from "./views/DownloadView";
import { useEffect } from "react";

import { analytics } from "./amplitude";
import CreateEventLanding from "./views/Landings/CreateEvent/CreateEventLandingView";
import CreateEventFastView from "./views/Landings/CreateEvent/CreateEventFastView";
import LoginLandingView from "./views/Landings/CreateEvent/LoginLandingView";
import FindGroupEvents from "./views/Landings/FindEvents/FindGroupEventsView";
import ChatBotView from "./views/Landings/ChatBot/ChatBotView";

const App = () => {
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      analytics
        .getInstance()
        .logEvent("PageChanged", { newLocation: location.pathname });
    });
  }, [history]);

  return (
    <UserProvider>
      <LoginUIProvider>
        <Switch>
          <Route exact path="/">
            <MainLayout>
              <MapView />
            </MainLayout>
          </Route>
          <Route exact path="/subscribe">
            <MainLayout>
              <DownloadView />
            </MainLayout>
          </Route>
          <Route exact path="/chat-bot-landing">
            <ChatBotView />
          </Route>
          <Route exact path="/find-group-event-landing">
            <MainLayout>
              <FindGroupEvents />
            </MainLayout>
          </Route>
          <Route exact path="/login-landing">
            <MainLayout>
              <LoginLandingView />
            </MainLayout>
          </Route>
          <Route exact path="/create-event-landing">
            <MainLayout>
              <CreateEventLanding />
            </MainLayout>
          </Route>
          <Route exact path="/create-event-fast">
            <MainLayout>
              <CreateEventFastView />
            </MainLayout>
          </Route>
          <Route exact path="/download">
            <MainLayout>
              <DownloadView />
            </MainLayout>
          </Route>
          <Route exact path="/meditation-places">
            <MainLayout>
              <MeditationMapView />
            </MainLayout>
          </Route>
          <Route exact path="/survey">
            <MainLayout>
              <SurveyView />
            </MainLayout>
          </Route>
          <Route exact path="/finish-survey">
            <MainLayout>
              <FinishSurveyView />
            </MainLayout>
          </Route>
          <Route path="/events/:eventId">
            <MainLayout>
              <MapView />
            </MainLayout>
          </Route>
          <Route exact path="/profile/">
            <MainLayout>
              <MyProfileView />
            </MainLayout>
          </Route>
          <Route exact path="/profile/:profileId">
            <MainLayout>
              <OtherProfileView />
            </MainLayout>
          </Route>
          <Route exact path="/help">
            <MainLayout>
              <HelpView />
            </MainLayout>
          </Route>
          <Route>
            <MainLayout>
              <div>
                Not found&nbsp;<Link to="/">Go to root</Link>
              </div>
            </MainLayout>
          </Route>
        </Switch>
      </LoginUIProvider>
    </UserProvider>
  );
};

declare let module: Record<string, unknown>;

export default hot(module)(App);
