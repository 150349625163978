import * as React from "react";
import { hot } from "react-hot-loader";
import { withRouter, Link, Redirect } from "react-router-dom";

import "./DownloadView.scss";
import {
  Button,
  Col,
  Radio,
  Row,
  Progress,
  Typography,
  Space,
  Input,
} from "antd";
import { useState } from "react";

import { analytics } from "../amplitude";

const DownloadView = () => {
  const [shouldShowThankYou, setShouldShowThankYou] = useState(false);
  const [shouldShowRequired, setShouldShowRequired] = useState(false);

  function onSubmit(e) {
    if (e) {
      analytics.getInstance().logEvent("SubscribedForUpdates", { email: e });
      setShouldShowThankYou(true);
      setShouldShowRequired(false);
    } else {
      setShouldShowRequired(true);
      setShouldShowThankYou(false);
    }
  }

  let thankYou = <></>;
  if (shouldShowThankYou) {
    thankYou = (
      <Row>
        <Col offset={1} span={22}>
          <Typography.Text type="success">Thank you!</Typography.Text>
        </Col>
      </Row>
    );
  }
  if (shouldShowRequired) {
    thankYou = (
      <Row>
        <Col offset={1} span={22}>
          <Typography.Text type={"danger"}>Please enter email.</Typography.Text>
        </Col>
      </Row>
    );
  }

  return (
    <div className={"container"}>
      <Row>
        <Col offset={1} span={22}>
          <Typography.Title title={"2"}>
            Subscribe for news and updates
          </Typography.Title>
        </Col>
      </Row>
      {thankYou}
      <Row>
        <Col offset={1} xl={{ span: 6 }} xs={{ span: 22 }}>
          <Input.Search
            placeholder="Email"
            allowClear
            enterButton="Submit"
            size="large"
            onSearch={onSubmit}
          />
        </Col>
      </Row>
    </div>
  );
};

declare let module: Record<string, unknown>;

export default hot(module)(withRouter(DownloadView));
