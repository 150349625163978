import * as React from "react";
import { hot } from "react-hot-loader";
import { withRouter, Redirect } from "react-router-dom";

import "./CreateEventLandingView.scss";
import { Button, Col, Row, Typography, Space } from "antd";
import { useState } from "react";

import { analytics } from "../../../amplitude";

const CreateEventLandingView = () => {
  const [shouldRedirectToMap, setShouldRedirectToMap] = useState(false);
  const [
    shouldRedirectToCreatEventFast,
    setShouldRedirectToCreatEventFast,
  ] = useState(false);

  function nextClick() {
    analytics.getInstance().logEvent("CreateEventLandingNextClicked");
    setShouldRedirectToCreatEventFast(true);
  }

  function skipClick() {
    analytics.getInstance().logEvent("CreateEventLandingSkipClicked");
    setShouldRedirectToMap(true);
  }

  if (shouldRedirectToMap) {
    return <Redirect to="/" />;
  }

  if (shouldRedirectToCreatEventFast) {
    return <Redirect to="/login-landing" />;
  }

  return (
    <div className={"container"}>
      <Row>
        <Col className={"text-aligned-center"} offset={1} span={22}>
          <Typography.Title level={2}>
            JoinMeOnHike helps you find hiking buddies
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col className={"text-aligned-center"} offset={1} span={22}>
          <Typography.Title level={3}>
            Let's create your first group hike
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col className={"text-aligned-center"} offset={1} span={22}>
          <Space size={"large"} align={"end"} direction="horizontal">
            <Button type="link" onClick={skipClick}>
              Skip
            </Button>
            <div>&nbsp;</div>
            <Button type="primary" onClick={nextClick}>
              Next
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

declare let module: Record<string, unknown>;

export default hot(module)(withRouter(CreateEventLandingView));
