import {
  MapViewState,
  EventState,
  getDefaultEventState,
} from "../MapViewState";
import { MapState, MapStateContext } from "./MapState";
import { ExploreMapState } from "./ExploreMapState";
import { CreateEventMapState } from "./CreateEventMapState";
import { Typography, Row, Col } from "antd";
import React from "react";
import moment from "moment";
import { Comment, Avatar, Form, Button, List, Input, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";

export class LoadingMapState extends MapState {
  render(context: MapStateContext): JSX.Element {
    return (
      <>
        <Row>
          <Col span={22} offset={1} style={{ textAlign: "center" }}>
            &nbsp;
          </Col>
        </Row>
        <Row>
          <Col span={22} offset={1} style={{ textAlign: "center" }}>
            <Spin size={"large"}></Spin>
          </Col>
        </Row>
      </>
    );
  }

  onMapClick(e: any, context: MapStateContext): MapState {
    return new ExploreMapState(this.triggerStateChange);
  }
}
