import * as React from "react";
import { createRef } from "react";
import { hot } from "react-hot-loader";
import { withRouter } from "react-router-dom";
import { mapbox } from "../../../mapbox";
import "mapbox-gl/dist/mapbox-gl.css";
import { Typography, Row, Col, Button, Space } from "antd";
import CreateEvent from "./CreateEvent";
import { auth, firebase, firestore } from "../../../firebase";

import {
  MapViewState,
  EventState,
  getDefaultEventState,
} from "../MapViewState";
import { MapState, MapStateContext } from "./MapState";
import { ViewEventMapState } from "./ViewEventMapState";
import { CreateEventMapState } from "./CreateEventMapState";

const { Title } = Typography;

export class ExploreMapState extends MapState {
  render(context: MapStateContext): JSX.Element {
    let body = <></>;
    if (context.viewState.canCreateEvent) {
      body = (
        <>
          <Row>
            <Col offset={1} span={22} style={{ textAlign: "center" }}>
              &nbsp;
            </Col>
          </Row>
          <Row>
            <Col offset={1} span={22} style={{ textAlign: "center" }}>
              <Typography.Title level={2}>
                Click to create your hike
              </Typography.Title>
            </Col>
          </Row>
        </>
      );
    } else {
      body = (
        <>
          <Row>
            <Col offset={1} span={22} style={{ textAlign: "center" }}>
              &nbsp;
            </Col>
          </Row>
          <Row>
            <Col offset={1} span={22} style={{ textAlign: "center" }}>
              <Typography.Title level={2}>Explore hikes</Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col offset={1} span={22} style={{ textAlign: "center" }}>
              <Typography.Title level={4}>or</Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col offset={1} span={22} style={{ textAlign: "center" }}>
              <Typography.Title level={2}>
                Zoom in to create your hike
              </Typography.Title>
            </Col>
          </Row>
        </>
      );
    }

    return (
      <>
        {body}
        <Row>
          <Col offset={1} span={22}>
            We've created this app to help people find hiking buddies.
            <br />
            Explore hikes on the map or create your own hike for friends.
            <br />
            <br />
            For questions -{" "}
            <a href={"mailto:joinmeonhike@gmail.com"}>joinmeonhike@gmail.com</a>
            .
          </Col>
        </Row>
      </>
    );
  }
  static fromOnMapClick(triggerStateChange, context: MapStateContext, e: any) {
    const mapState = new ExploreMapState(triggerStateChange);
    return mapState.onMapClick(e, context);
  }

  onMapClick(e, context: MapStateContext): MapState {
    const features = context.viewState.mapBox.queryRenderedFeatures(e.point, {
      layers: ["route_circles"],
    });
    if (0 < features.length) {
      return ViewEventMapState.fromOnMapClick(
        this.triggerStateChange,
        context,
        features,
      );
    } else {
      if (context.viewState.canCreateEvent) {
        return CreateEventMapState.fromOnMapClick(
          this.triggerStateChange,
          context,
          e,
        );
      } else {
        return this;
      }
    }
  }
}
