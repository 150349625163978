export type MapViewState = {
  eventState: EventState;
  mapBox: any;
  mapContainerRef: HTMLDivElement;
  lng: number;
  lat: number;
  zoom: number;
  isCreatingEvent: boolean;
  canCreateEvent: boolean;
  allEvents: Map<string, any>;
};

export type EventState = {
  routeCoordinates: Array<number>;
  clickCoordinates: Array<number>;
  clicks: number;
  routeInstructions: Map<number, any>;
  summary: string;
  distance: number;
  durations: Array<number>;
};

export function getDefaultEventState(): EventState {
  return {
    routeCoordinates: [],
    clickCoordinates: [],
    clicks: 0,
    routeInstructions: new Map<number, any>(),
    summary: "New event",
    distance: 0,
    durations: [],
  };
}
