import * as React from "react";
import { createRef, useContext, useEffect, useState } from "react";
import { hot } from "react-hot-loader";
import { withRouter } from "react-router-dom";
import { mapbox } from "../mapbox";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  Typography,
  Row,
  Col,
  Button,
  Tooltip,
  Spin,
  Avatar,
  Form,
  Comment,
  Input,
  List,
} from "antd";
import { auth, firebase, firestore } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { LoginUIContext } from "../providers/LoginUIProvider";
import * as moment from "moment";

const { Title } = Typography;
const { TextArea } = Input;

const OtherProfileView = ({ history, location, match }) => {
  const [user, loading, error] = useAuthState(auth);
  const [isLoginModalVisible, setIsLoginModalVisible] = useContext(
    LoginUIContext,
  );
  const [comments, setComments] = useState([]);
  const [profile, setProfile] = useState(null);
  const [form] = Form.useForm();
  const [comment, setComment] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleChange = ({ comment }) => {
    if (comment) {
      setComment(comment);
    }
  };

  async function handleSubmit() {
    if (!comment) {
      return;
    }

    if (!loading && user) {
      setSubmitting(true);

      const obj = {
        value: comment,
        ownerPhotoUrl: user.photoURL,
        ownerDisplayName: user.displayName,
        fromUserId: user.uid,
        toUserId: profile.id,
        created: new Date(),
      };
      const doc = await firestore.collection("messages").add(obj);

      const newComments = comments;
      newComments.push({
        author: obj.ownerDisplayName,
        avatar: obj.ownerPhotoUrl,
        content: obj.value,
        created: obj.created,
        datetime: (
          <Tooltip title={moment(obj.created).format("YYYY-MM-DD HH:mm:ss")}>
            <span>{moment(obj.created).fromNow()}</span>
          </Tooltip>
        ),
      });

      setComment("");
      setComments(newComments);
      setSubmitting(false);
      form.resetFields();
    } else {
      setIsLoginModalVisible(true);
    }
  }

  useEffect(() => {
    if (user === null) {
      return;
    }

    const { profileId } = match.params;
    firestore
      .collection("profiles")
      .doc(profileId)
      .get()
      .then((p) => {
        setProfile(p);

        firestore
          .collection("messages")
          .where("toUserId", "==", p.id)
          .where("fromUserId", "==", user.uid)
          .get()
          .then((snap) => {
            const c = [];
            for (const doc of snap.docs) {
              c.push({
                author: doc.get("ownerDisplayName"),
                avatar: doc.get("ownerPhotoUrl"),
                content: doc.get("value"),
                created: doc.get("created"),
                datetime: (
                  <Tooltip
                    title={moment(doc.get("created").toDate()).format(
                      "YYYY-MM-DD HH:mm:ss",
                    )}
                  >
                    <span>{moment(doc.get("created").toDate()).fromNow()}</span>
                  </Tooltip>
                ),
              });
            }
            setComments(
              c.sort(function (a, b) {
                if (a.created > b.created) {
                  return 1;
                }
                if (a.created < b.created) {
                  return -1;
                }
                return 0;
              }),
            );
          });
      });
  }, [user]);

  function pleaseLogin() {
    setIsLoginModalVisible(true);
  }

  if (!loading && !user) {
    {
      return (
        <div style={{ backgroundColor: "#fff" }}>
          <Row>
            <Col span={24}>&nbsp;</Col>
          </Row>
          <Row>
            <Col span={24}>&nbsp;</Col>
          </Row>
          <Row>
            <Col span={10}></Col>
            <Col span={4}>
              <Button onClick={pleaseLogin} type="primary">
                Log in
              </Button>
            </Col>
          </Row>
        </div>
      );
    }
  }

  if (null != profile) {
    let commentHtml = <></>;
    if (comments.length !== 0) {
      commentHtml = (
        <Row>
          <Col span={4}></Col>
          <Col span={16}>
            <List
              dataSource={comments}
              itemLayout="horizontal"
              renderItem={(props) => <Comment {...props} />}
            />
          </Col>
        </Row>
      );
    }

    return (
      <div style={{ backgroundColor: "#fff" }}>
        <Row>
          <Col span={4}></Col>
          <Col span={16}>&nbsp;</Col>
        </Row>
        <Row>
          <Col span={4}></Col>
          <Col span={2}>
            <Avatar
              size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
              src={profile.get("photoUrl")}
            />
          </Col>
          <Col span={14}>
            <Typography.Title level={3}>
              {" "}
              {profile.get("displayName")}
            </Typography.Title>
            On site since{" "}
            {moment(profile.get("created").toDate()).format("Do MMM YYYY")}
          </Col>
        </Row>
        <Row>
          <Col span={4}></Col>
          <Col span={16}>&nbsp;</Col>
        </Row>
        <Row>
          <Col span={4}></Col>
          <Col span={16}>
            <Typography.Title level={4}>Messages</Typography.Title>
          </Col>
        </Row>
        {commentHtml}
        <Row>
          <Col span={4}></Col>
          <Col span={16}>
            <Comment
              avatar={<Avatar src={user.photoURL} alt={user.displayName} />}
              content={
                <>
                  <Form
                    layout={"vertical"}
                    initialValues={{ comment: comment }}
                    form={form}
                    onValuesChange={handleChange}
                  >
                    <Form.Item name="comment">
                      <TextArea rows={4} />
                    </Form.Item>
                  </Form>
                  <Button
                    htmlType="submit"
                    loading={submitting}
                    onClick={handleSubmit}
                    type="primary"
                  >
                    Send
                  </Button>
                </>
              }
            />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div style={{ backgroundColor: "#fff" }}>
        <Row>
          <Col span={24}>&nbsp;</Col>
        </Row>
        <Row>
          <Col span={24}>&nbsp;</Col>
        </Row>
        <Row>
          <Col span={10}></Col>
          <Col span={4}>
            <Spin size="large" />
          </Col>
        </Row>
      </div>
    );
  }
};

declare let module: Record<string, unknown>;

export default hot(module)(withRouter(OtherProfileView));
